import { DataAccessService } from './../../data-access.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectComponent } from 'ng-select';
declare var $: any;

@Component({
  selector: 'app-opening-stock',
  templateUrl: './opening-stock.component.html',
  styleUrls: ['./opening-stock.component.scss']
})
export class OpeningStockComponent implements OnInit {
  @ViewChild('prod') selectComponent: SelectComponent;
  isStockAdjustment = true;
  username = '';
  password = '';
  isInit = false;
  products;
  products1 = [];
  shelfs;
  gst_type = 'GST';
  productList = [];
  index = 0;
  gross_total = 0;
  tax_total = 0;
  net_total = 0;
  isSavePurchase = 0;

  product = '';
  p_no = '';
  mrp = 0;
  rate = 0;
  gst = 0;
  taxable = 0;
  qty = 0;
  m_name = '';
  s_code = '';
  expiry_date = '';
  batch_no = '';
  pack_size = '';
  sgst = 0;
  cgst = 0;
  igst = 0;
  gst_amt = 0;
  net_rate = 0;
  shelf = '';
  p_cat = '';
  generic = '';
  templist = {};

  newProductIndex = 0;
  generics;
  categories;
  pack_sizes;
  dosages;
  manufacturers;
  constructor(private service: DataAccessService) {
   }

  ngOnInit() {
    
  }

  getProducts(value) {
    if (value.length >= 2 && value.length < 4) {
      this.service.get('purchase.php?type=getProducts&p_name=' + value).subscribe(response => {
        this.products = response;
        const len = Object.keys(this.products).length;
        let index = 0;
        for (let i = 0; i < len; i++) {
          this.products1[index] = this.products[i].p_name;
          index++;
        }
        $('#productsearch').autocomplete({
          source: this.products1,
          minLength: 2,
          messages: {
            noResults: this.NoProduct(value),
            results: function () {}
          }
        });
      });
    }
  }

  NoProduct(value) {
    if (value.length > 2) {
      if (this.newProductIndex === 0) {
        const ans = confirm('Add this Product in List ?');
        this.product = '';
        if (ans === true) {
          this.newProductIndex = 1;
          $('#productModal').modal('show');
        }
      }
    }
  }

  closeProdModal() {
    let element1 = document.getElementById('productsearch') as HTMLElement;
    element1.focus();
    this.newProductIndex = 0;
    $('#productModal').modal('hide');
  }

  getShelfs() {
    this.service.get('opening_stock.php?type=getShelfs').subscribe(reponse => {
      this.shelfs = reponse;
    });
  }

  getMRP(value) {
    const len = Object.keys(this.products).length;
    for (let i = 0; i < len; i++) {
      if (this.products[i].p_name === value) {
        this.product = this.products[i].p_name;
        this.p_no = this.products[i].p_no;
        this.mrp = +this.products[i].mrp;
        this.p_cat = this.products[i].p_cat;
        this.generic = this.products[i].generic;
        this.pack_size = this.products[i].pack_size;
        this.m_name = this.products[i].m_name;
        this.s_code = this.products[i].s_code;
        this.rate = parseFloat((this.mrp - (this.mrp * (28 / 100))).toFixed(2));
        this.gst = 12;
        this.taxable = parseFloat((this.rate * this.qty).toFixed(2));
      }
    }
  }

  calculateTaxable() {
    this.taxable = parseFloat((this.rate * this.qty).toFixed(2));
    this.calculateGST();
  }

  calculateGST() {
    this.gst_amt = parseFloat((this.taxable * (this.gst / 100)).toFixed(2));
    this.sgst = parseFloat((this.gst_amt / 2).toFixed(2));
    this.cgst = parseFloat((this.gst_amt / 2).toFixed(2));
    this.net_rate = parseFloat((this.taxable + this.gst_amt).toFixed(2));
  }

  addProduct() {
    if (this.product !== '' && this.batch_no !== '' && this.expiry_date !== '' && this.qty !== 0 && this.mrp !== 0 && this.rate !== 0) {
      this.templist['product'] = this.product;
      this.templist['p_no'] = this.product;
      this.templist['mrp'] = this.mrp;
      this.templist['batch_no'] = this.batch_no;
      this.templist['m_name'] = this.s_code;
      this.templist['expiry_date'] = this.expiry_date;
      this.templist['qty'] = this.qty;
      this.templist['scheme'] = '';
      this.templist['disc'] = 0;
      this.templist['hsn'] = '';
      this.templist['scheme_netrate'] = '';
      this.templist['gst'] = this.gst;
      this.templist['shelf'] = this.shelf;
      this.templist['taxable'] = this.taxable;
      this.templist['disc_amt'] = 0;
      this.templist['purchase_rate'] = this.rate;
      this.templist['sgst'] = this.sgst;
      this.templist['cgst'] = this.cgst;
      this.templist['igst'] = 0;
      this.templist['p_cat'] = this.p_cat;
      this.templist['generic'] = this.generic;
      this.templist['pack_size'] = this.pack_size;
      this.templist['net_rate'] = this.net_rate;
      this.templist['gst_amt'] = this.gst_amt;
      this.templist['scheme'] = 0;
      this.productList[this.index] = this.templist;
      this.index++;
      this.gross_total = this.gross_total + this.taxable;
      this.tax_total = parseFloat((this.tax_total + +this.gst_amt).toFixed(2));
      this.net_total = this.gross_total + this.tax_total;
      this.selectComponent.open();
      this.clearProductList();
    }
  }

  deleteProduct(index) {
    this.gross_total = this.gross_total - this.productList[index].taxable;
    // tslint:disable-next-line:max-line-length
    this.tax_total = parseFloat((this.tax_total - this.gst_amt).toFixed(2));
    this.net_total = this.gross_total + this.tax_total;
    this.productList.splice(index, 1);
    this.index--;
   }

  clearProductList() {
    this.product = '';
    this.p_no = '';
    this.mrp = 0;
    this.rate = 0;
    this.gst = 0;
    this.taxable = 0;
    this.qty = 0;
    this.m_name = '';
    this.s_code = '';
    this.expiry_date = '';
    this.batch_no = '';
    this.pack_size = '';
    this.sgst = 0;
    this.cgst = 0;
    this.igst = 0;
    this.gst_amt = 0;
    this.net_rate = 0;
    this.shelf = '';
    this.p_cat = '';
    this.generic = '';
    this.templist = {};
  }

  getGenerics() {
    this.service.get('opening_stock.php?type=getGenerics').subscribe(reponse => {
      this.generics = reponse;
    });
  }

  getCategories() {
    this.service.get('opening_stock.php?type=getCategories').subscribe(reponse => {
      this.categories = reponse;
    });
  }

  getPackSizes() {
    this.service.get('opening_stock.php?type=getPackSizes').subscribe(reponse => {
      this.pack_sizes = reponse;
    });
  }

  getDosages() {
    this.service.get('opening_stock.php?type=getDosages').subscribe(reponse => {
      this.dosages = reponse;
    });
  }

  getManufacturers() {
    this.service.get('opening_stock.php?type=getManufacturers').subscribe(reponse => {
      this.manufacturers = reponse;
    });
  }

  showProductModal() {
    if (this.newProductIndex === 0) {
      const ans = confirm('Add this Product in List ?');
      this.product = '';
      if (ans === true) {
        this.newProductIndex = 1;
        $('#productModal').modal('show');
      }
    }
  }

  saveProduct(productData) {
    this.service.post('master.php?type=saveProduct', JSON.stringify(productData.value))
     .subscribe(response => {
       if (response['status'] === 'success') {
        productData.reset();
        this.newProductIndex = 0;
        $('#productModal').modal('hide');
        this.product = '';
        this.service.get('opening_stock.php?type=getProducts').subscribe(reponse1 => {
          this.products = reponse1;
          this.selectComponent.open();
        });
       } else {
          alert('An error has occurred.');
       }
       },
     (error: Response) => {
       if (error.status === 400) {
         alert('An error has occurred.');
       } else {
         alert('An error has occurred, http status:' + error.status);
       }
     });
   }

   savePurchase() {
    if (this.isSavePurchase !== 0) {
      return;
    }
    this.isSavePurchase = 1;
    const temp = new Object();
    temp['products'] = this.productList;
    temp['gross_total'] = this.gross_total;
    temp['disc_total'] = 0;
    temp['tax_total'] = this.tax_total;
    temp['net_total'] = this.net_total;
    temp['gst_type'] = this.gst_type;
    temp['invoice_no'] = 'Opening Stock';
    temp['invoice_date'] = new Date();
    temp['distributor_name'] = 'Opening Stock';
    if (this.index === 0) {
      this.isSavePurchase = 0;
      alert('At least 1 product is Required in Product List');
      return;
    }
    this.service.post('purchase.php?type=savePurchase', JSON.stringify(temp))
    .subscribe(response => {
      this.isSavePurchase = 0;
      if (response['status'] === 'success') {
        alert('Purchase has been saved.');
        this.index = 0;
        this.productList = [];
        this.gross_total = 0;
        this.tax_total = 0;
        this.net_total = 0;
        this.clearProductList();
      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  checkLogin() {
    if (this.username === 'stock' && this.password === '1234') {
      this.isInit = true;
      this.getShelfs();
      this.getGenerics();
      this.getCategories();
      this.getPackSizes();
      this.getDosages();
      this.getManufacturers();
    } else {
      alert('Invalid Username OR Password');
    }
  }

}
