import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from '../../data-access.service';

@Component({
  selector: 'app-master-dashboard',
  templateUrl: './master-dashboard.component.html',
  styleUrls: ['./master-dashboard.component.scss']
})
export class MasterDashboardComponent implements OnInit {
  role;
  isSalesman = false;
  isAdmin = false;
  isPurchase = false;
  constructor(private router: Router,private service: DataAccessService) {
    this.role = localStorage.getItem('role');
    if (this.role === 'Admin') {
      this.isAdmin = true;
      this.isSalesman = false;
      this.isPurchase = false;
    } else if (this.role === 'Salesman') {
      this.isSalesman = true;
      this.isPurchase = false;
      this.isAdmin = false;
    } else if (this.role === 'Purchase') {
      this.isPurchase = true;
      this.isSalesman = false;
      this.isAdmin = false;
    }
  }

navigateTo(value) {
    if (value) {
        this.router.navigate([value]);
    }
    return false;
}

  ngOnInit() {
    this.getDiscount();
    this.getSHELF();
    this.getMaxDiscount();
  }


  isMargine: boolean = false;
 



  AddMarg(value){

    this.isMargine = !this.isMargine;
    this.router.navigate([value]);

  }
  AddMarg1(){
    let value = 'patient';
    this.isMargine = !this.isMargine;
    this.router.navigate([value]);

  }

  margineList;

  getSHELF() {
    this.service.get('master.php?type=getRetailMargin')
    .subscribe(response => {
      this.margineList = response;
    });
  }
  discList;

  getDiscount() {
    this.service.get('master.php?type=getCreditMargine')
    .subscribe(response => {
      this.discList = response;
    });
  }
  


  getMaxDiscount() {
    this.service.get('master.php?type=getMaxDiscount')
    .subscribe(response => {
      this.maxDiscount1 = response['maxDiscount'];
    });
  }


  saveSHELF(shelfData) {
    this.service.post('master.php?type=saveMargin', JSON.stringify(shelfData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        shelfData.reset();
        this.getSHELF();
        alert('Margin Added Successfully.');
      } else {
        alert(response['status']);
      }
      });
  }
 
  saveCredit(shelfData) {
    this.service.post('master.php?type=saveCredit', JSON.stringify(shelfData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        shelfData.reset();
        this.getDiscount();
        alert('Credit Added Successfully.');
      } else {
        alert(response['status']);
      }
      });
  }


  maxDiscount1 = 0;
  maxDiscount = 0;
  cashDiscount = 0;


  checkDisc(){
 
    if(this.cashDiscount > this.maxDiscount1){
      alert(' Cash Discount Cant Be Greater Than Max Discount !!!!!!!!!!!!');
      this.cashDiscount = this.maxDiscount1;
    }
     
  }

  saveMAxDiscount() {

    let temp ={};
    this.service.post('master.php?type=saveMaxDiscount&maxDiscount='+this.maxDiscount, JSON.stringify(temp))
    .subscribe(response => {
      if (response['status'] === 'success') {
         
        this.getMaxDiscount();
        alert('Max Doscount Saved Successfully.');
      } else {
        alert(response['status']);
      }
      });
  }
 

  formType = 'ADD MARGIN';



}
